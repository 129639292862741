/* Normalize and Base Styles */
@import url("https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Roboto+Mono:wght@400;700&display=swap");

/* Variables for Colors, Fonts, and Spacing */
:root {
  /* Colors */
  --color-bg: #181818; /* Dark background */
  --color-card: #242424; /* Slightly lighter card background */
  --color-accent: #1db954; /* Green accent */
  --color-text: #f5f5f5; /* Light text */
  --color-subtext: #b3b3b3; /* Subtle gray for secondary text */
  --color-border: #333333; /* For light borders */
  --color-hover: rgba(255, 255, 255, 0.1); /* Subtle hover background */

  /* Fonts */
  --font-primary: "Inter", sans-serif;
  --font-mono: "Roboto Mono", monospace;

  /* Typography Sizes */
  --font-size-h1: 2.5rem;
  --font-size-h2: 2rem;
  --font-size-h3: 1.5rem;
  --font-size-body: 1rem;
  --font-size-small: 0.875rem;

  /* Spacing */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;

  /* Borders */
  --border-radius: 8px;
}

/* General Styles */
html,
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-primary);
  background-color: var(--color-bg);
  color: var(--color-text);
  height: 100%; /* Ensure full height for layout */
  line-height: 1.6; /* Comfortable line spacing */
  font-size: var(--font-size-body);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%; /* Ensure the root container fills the viewport */
}

/* Typography */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 600;
  color: var(--color-text);
}

h1 {
  font-size: var(--font-size-h1);
  margin-bottom: var(--spacing-md);
}

h2 {
  font-size: var(--font-size-h2);
  margin-bottom: var(--spacing-sm);
}

h3 {
  font-size: var(--font-size-h3);
  color: var(--color-subtext);
  margin-bottom: var(--spacing-xs);
}

p {
  font-size: var(--font-size-body);
  margin: var(--spacing-sm) 0;
}

small {
  font-size: var(--font-size-small);
  color: var(--color-subtext);
}

/* Links */
a {
  color: var(--color-accent);
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s;
}

a:hover {
  color: #1ed760; /* Slightly lighter accent */
}

/* Utility Classes */
.flex {
  display: flex;
  gap: var(--spacing-md);
}

.center {
  justify-content: center;
  align-items: center;
}

.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: var(--spacing-lg);
}

.full-width {
  width: 100%;
}

/* Buttons */
button {
  background-color: var(--color-accent);
  color: var(--color-bg);
  border: none;
  border-radius: var(--border-radius);
  padding: var(--spacing-sm) var(--spacing-md);
  font-family: var(--font-primary);
  font-size: var(--font-size-body);
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #1ed760; /* Slightly lighter green */
}

button:disabled {
  background-color: var(--color-border);
  color: var(--color-subtext);
  cursor: not-allowed;
}

/* Cards */
.card {
  background-color: var(--color-card);
  padding: var(--spacing-md);
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Forms */
input,
textarea,
select {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  background-color: var(--color-card);
  color: var(--color-text);
  font-family: var(--font-primary);
  font-size: var(--font-size-body);
}

input:focus,
textarea:focus,
select:focus {
  border-color: var(--color-accent);
  outline: none;
}

/* Tables */
table {
  width: 100%;
  border-collapse: collapse;
  margin: var(--spacing-md) 0;
  font-size: var(--font-size-body);
}

th,
td {
  padding: var(--spacing-sm);
  text-align: left;
  border: 1px solid var(--color-border);
}

th {
  background-color: var(--color-card);
  color: var(--color-text);
}

/* Footer */
footer {
  background-color: var(--color-card);
  color: var(--color-subtext);
  text-align: center;
  padding: var(--spacing-lg);
  margin-top: auto;
}

/* Media Queries */
@media (max-width: 768px) {
  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }
}
